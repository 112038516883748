import React from "react";
import styled, { css } from "styled-components";
import getConfig from "next/config";
import Link from "next/link";

import LinkButton from "../styled/LinkButton";
import Logo from "../Logo";

const { publicRuntimeConfig } = getConfig();

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  min-height: 74px;
  background: #001e5a;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 980px;

    padding-top: 8px;
    padding-bottom: 12px;
    padding-left: 15px;
    padding-right: 15px;

    .header-logo {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      padding-top: 4px;
      padding-bottom: 4px;

      cursor: pointer;

      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;

        &:first-child {
          font-family: 'Arial';
          font-weight: bold;
        }
      }
    }
  }

  ${({ showHomeView }) => !showHomeView && css`
    align-items: flex-start;
    min-height: 69px;
    background: #001e5a;

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .header-nav {
      .header-nav-item {
        a {
          border: 1px solid #FFFFFF;
        }
      }
    }
  `};

  @media ${({ theme }) => theme.devices.tablet} {
    .header-container {
      .header-nav {
      .header-nav-item {
        a {
          font-size: 14px;
        }
      }
    }
    }
  }
`;

function Header({ showHomeView, showOrderStatusButton, showIncorporateLLCButton }) {
  return (
    <StyledHeader showHomeView={showHomeView}>
      <div className="header-container">
        {!showHomeView && (
          <Link href="/">
            <a>
              <Logo />
            </a>
          </Link>
        )}
        {showOrderStatusButton && (
          <div className="header-nav">
            <div className="header-nav-item">
              <Link href="/status">
                <LinkButton>
                  Check Order Status
                </LinkButton>
              </Link>
            </div>
          </div>
        )}
        {/* {showIncorporateLLCButton && (
          <div className="header-nav">
            <div className="header-nav-item">
              <Link href="/incorps/home">
                <LinkButton>
                  Incorporate LLC
                </LinkButton>
              </Link>
            </div>
          </div>
        )} */}
      </div>
    </StyledHeader>
  );
}

export default Header;
