import React from "react";
import styled from "styled-components";
const skin = process.env.NEXT_PUBLIC_REACT_APP_SKIN;
const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  color: white;

  img {
    height: 40px;
    margin-top: ${skin === "irsein" ? "-14px": "-4px"};
    cursor: pointer;
  }

  .logo-title  {
    display: inline-flex;
    flex-direction: column;
    margin-left: 12px;
    text-align: flex-start;

    .logo-text  {
      font-family: 'MerriweatherBold';
      font-size: 28px;
      line-height: 28px;
      font-weight: 600;
      white-space: nowrap;
    }

    .logo-subtext  {
      font-family: 'MerriweatherLight';
      font-size: 14px;
      line-height: 18px;
    }
  }
  .logo-title2  {
    display: inline-flex;
    flex-direction: column;
    margin-left: 12px;
    text-align: left;

    .logo-text2  {
      font-family: 'MerriweatherBold';
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 4px;

    img {
      margin-top: 0;
      max-width: 95%;
    }

    .logo-title  {
      .logo-text  {
        font-size: 18px;
        line-height: 18px;
      }

      .logo-subtext  {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .logo-title2  {
      .logo-text2  {
        font-size: 11px;
        line-height: 20px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }
`;

const Logo = () => {
  return (
    <StyledLogo>
        <span className="logo-title">
          <span className="logo-text">Tax ID/EIN Application {`(${new Date().getFullYear()})`}</span>
          <span className="logo-subtext">Approved 3rd Party eFile Provider</span>
      </span>
    </StyledLogo>
  );
};

export default Logo;
