import React from "react";
import styled, { css } from "styled-components";
import getConfig from "next/config";
import Link from "next/link";

import MobileMenu from "../MobileMenu";

const { publicRuntimeConfig } = getConfig();

const StyledNavBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-right: 10%;
  padding-left: 10%;
  padding-top: 14px;
  padding-bottom: 14px;

  background: #1B3858;

  .nav-logo {
    margin-right: 40px;
  }

  .nav-title {
    margin-right: 16px;
    a {
      font-family: 'MerriweatherLight';
      font-size: 28px;
      line-height: 36px;
      text-transform: capitalize;
      text-decoration: none;
      white-space: nowrap;
      color: #FFFFFF;
    }
    .logo {
      display: flex;
      flex-direction: column;
    }
  }

  .nav-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;

    a {
      display: block;

      padding-left: 10px;
      padding-right: 10px;

      font-family: 'MerriweatherLight';
      font-size: 16px;
      line-height: 26px;
      text-decoration: none;
      white-space: nowrap;
      color: #FFFFFF;
    }
  }

  .mobile-nav-menu {
    display: none;
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    padding-right: 4%;
    padding-left: 4%;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .nav-title {
      a {
        span {
          display: block;
          text-align: center;

          &:last-child {
            margin-top: 4px;
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }

    .nav-menu {
      display: ${({ withBurger }) => withBurger ? 'none' : 'flex'};
      flex-direction: column;
      margin-bottom: 20px;

      a {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

    ${({ withBurger }) => !withBurger && css`
      flex-direction: column;
      padding-top: 20px;

      .nav-logo {
        margin-bottom: 20px;
        margin-right: 0;
      }

      .nav-title {
        margin-bottom: 20px;
        margin-right: 0;
      }
    `};

    ${({ withBurger }) => withBurger && css`
      .nav-logo {
        margin-right: 16px;

        img {
          width: 60px;
        }
      }

      .nav-title {
        a {
          span {
            text-align: left;

            &:first-child {
              font-size: 20px;
              line-height: 28px;
            }

            &:last-child {
              margin-top: 0;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }

      .mobile-nav-menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
      }
    `};
  }
`;

function NavBar({ withBurger }) {
  return (
    <StyledNavBar withBurger={withBurger}>
      <div className="nav-title">
        <Link href="/">
          <a>
            <div className="logo">
          <span className="logo-text">Tax ID/EIN Application {`(${new Date().getFullYear()})`}</span>
          <span className="logo-subtext">Approved 3rd Party eFile Provider</span>
            </div>
          </a>
        </Link>
      </div>
      <div className="nav-menu">
        {/* <Link href="/"><a>Home</a></Link> */}
        {/* <Link href="/terms"><a>Terms</a></Link> */}
        {/* <Link href="/pricing"><a>Pricing</a></Link> */}
        {/* <Link href="/privacy"><a>Privacy</a></Link> */}
        {/* <Link href="/about"><a>About</a></Link> */}
        {/* <Link href="/incorps/home"><a>Incorporate LLC</a></Link> */}
        <Link href="/contactus"><a>Contact Us</a></Link>
        <Link href="/status"><a>Order Status</a></Link>
      </div>
      <div className="mobile-nav-menu">
        <MobileMenu />
      </div>
    </StyledNavBar>
  );
}

export default NavBar;
